import { Flex, CircularProgress } from "@chakra-ui/react";

type Props = {
  color?: string;
  iconSize?: number;
  [key: string]: unknown;
};

const CenterSpinner = ({ color, iconSize, ...rest }: Props) => (
  <Flex justify="center" align="center" {...rest}>
    <CircularProgress
      isIndeterminate
      color={color || `${"#000"}`}
      size={iconSize || "24px"}
    />
  </Flex>
);

export default CenterSpinner;
