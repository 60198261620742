import { useEffect } from "react";

const LiveChat = () => {
  useEffect(() => {
    window.__lc = window.__lc || {};
    window.__lc.license = 12868335;
    window.__lc.integration_name = "manual_channels";
    window.__lc.product_name = "livechat";

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.livechatinc.com/tracking.js";
    script.type = "text/javascript";
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <noscript>
      <a
        href="https://www.livechat.com/chat-with/12868335/"
        rel="noreferrer"
        target="_blank"
      >
        Chat with us
      </a>
      , powered by
      <a href="https://www.livechat.com/" rel="noreferrer" target="_blank">
        LiveChat
      </a>
    </noscript>
  );
};

export default LiveChat;
