import { AxiosError } from "axios";
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction } from "react-query";
import axios from "src/services/network/axios";
import { useUserAccount } from "src/store/userAccountState";
import { StringOrNull } from "src/types";
import { QueryKeyType } from "../../paginationQuery/useDataWithPagination";
import { useToast } from "@chakra-ui/react";
import {
  getActiveSubscriptionsKeys,
  SubscriptionsOptionsType,
} from "../getSubscriptionsKeys";
import useIsShowSubscriptionTab from "src/hooks/useIsShowSubscriptionTab";
import chainGet from "src/services/network/chainGet";
import { useCompanySelection } from "src/store/companySelectionState";

export type PackageType = {
  price: number;
  name: string;
  isRecurring: false;
  aiReceptionistMinutesInPackage: number | null;
  liveReceptionistMinutesInPackage: number | null;
  liveChatsInPackage: number;
  id: string;
  code: string;
  vmBoxCode: StringOrNull;
  cancelled: false;
  discount: number | null;
  canBeRemoved: boolean;
  oppId: StringOrNull;
  type: string;
  integration?: string;
  FAQLimit?: number;
  contactLimit?: number;
  schedule?: string;
  description?: string;
  aiCallAllowance?: number;
  isAIReceptionist: boolean | null;
  isLiveReceptionist: boolean | null;
};

export type AiReceptionistServiceType = {
  userHasTrial: boolean | null;
  userHasPaidService: boolean | null;
  userHasNoService: boolean | null;
  trialIsClosed: boolean | null;
  serviceIsPaidAndClosed: boolean | null;
  serviceIsClosed: boolean | null;
  closedTrialStage1To4: boolean | null;
  activeTrialStage1To4: boolean | null;
};

// canBeRemoved: true;
// cancelled: null;
// code: "2499";
// discount: null;
// id: "01t77000000H3AlAAK";
// liveChatsInPackage: null;
// liveReceptionistMinutesInPackage: null;
// name: "Additional Auto Voicemail Transcription";
// price: 6.39;
// vmBoxCode: "21005";
export type ActiveSubscriptionType = {
  totalRecurringPrice: number | null;
  oneTimePayment: number | null;
  totalPrice: number;
  status: string;
  packages: PackageType[];
  orderNumber: string;
  nextDueDate: string;
  name: string;
  liveChatFreeTrialEndDate: string;
  invoiceId: string;
  description: string | null;
  closeDate: string;
  futurePackages: PackageType[];
  futurePackagesTotalRecurringPrice: number | null;

  amountDue: number;
  amount: number;
  abbyFreeTrialEndDate: string;
  billingStartDate: number;
  billingStartDateLiveReceptionist: string;
  billingStartDateAIReceptionist: string;
  billingStartDateLiveChat: string;
  billingEndDateLiveReceptionist: string;
  billingEndDateAIReceptionist: string;
  billingEndDateLiveChat: string;
  receptionistTrial: {
    totalRecurringPrice: number;
    closeDate: string;
    packages: PackageType[];
  };
  chatTrial: {
    totalRecurringPrice: number;
    closeDate: string;
    packages: PackageType[];
  };
  lcCustomPackages: null | PackageType[];
  lrCustomPackages: null | PackageType[];
  airCustomPackages: null | PackageType[];
  aiReceptionistService: AiReceptionistServiceType;
  aiReceptionistTrial: {
    totalRecurringPrice: number;
    packages: PackageType[];
  };
  aiReceptionistFreeTrialUsed: boolean | null;
  aiReceptionistFreeTrialStart: StringOrNull;
  aiReceptionistFreeTrialEnd: StringOrNull;
};

type Params = {
  options?: UseQueryOptions<
    ActiveSubscriptionType,
    AxiosError,
    ActiveSubscriptionType
  >;
};

const useActiveSubscriptionQuery = (params?: Params) => {
  const options = chainGet(params?.options, {});
  const enabledParams = options["enabled"];

  const toast = useToast();
  const { userAccount } = useUserAccount();
  const businessAccountId = userAccount?.businessAccountId;
  const { activeCompany } = useCompanySelection();
  const queryKey = getActiveSubscriptionsKeys({
    businessAccountId,
    companyId: activeCompany,
  });

  const isShowSubscription = useIsShowSubscriptionTab();

  const enabled = isShowSubscription && !!businessAccountId;
  const query = useQuery({
    queryKey,
    queryFn: fetchActiveSubscriptions,
    enabled: typeof enabledParams === "boolean" ? enabledParams : enabled,
    onError: (err: AxiosError) => {
      const errMessage = err?.response?.data?.message;

      toast({
        title: "Error",
        description: errMessage || "Failed.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });
  return query;
};

export default useActiveSubscriptionQuery;
const fetchActiveSubscriptions: QueryFunction<
  ActiveSubscriptionType,
  QueryKeyType<SubscriptionsOptionsType>
> = async ({ queryKey }) => {
  const options = queryKey[1];
  const businessAccountId = options.businessAccountId;
  const companyId = options.companyId;

  const request = await axios.get(
    `/businessAccounts/${businessAccountId}/subscription?companyId=${companyId}`
  );
  return request?.data || [];
};

// SAMPLE RESPONSE**
// const foo = {
//   totalRecurringPrice: null,
//   totalPrice: null,
//   status: null,
//   receptionistTrial: {
//     totalRecurringPrice: 309,
//     packages: [
//       {
//         vmBoxCode: null,
//         price: 10,
//         oppId: "00k77000003GewiAAC",
//         name: "Additional Phone Number",
//         liveReceptionistMinutesInPackage: null,
//         liveChatsInPackage: null,
//         id: "01ti0000000Ee4UAAS",
//         discount: null,
//         code: "2248",
//         cancelled: false,
//         canBeRemoved: true,
//       },
//       {
//         vmBoxCode: null,
//         price: -100,
//         oppId: "00k77000003GewjAAC",
//         name: "Referral Discount",
//         liveReceptionistMinutesInPackage: null,
//         liveChatsInPackage: null,
//         id: "01t77000000YBfoAAG",
//         discount: null,
//         code: "9013",
//         cancelled: false,
//         canBeRemoved: false,
//       },
//       {
//         vmBoxCode: null,
//         price: 299,
//         oppId: "00k77000003GewkAAC",
//         name: "Live Receptionist 100",
//         liveReceptionistMinutesInPackage: 100,
//         liveChatsInPackage: null,
//         id: "01ti0000006PHz3AAG",
//         discount: null,
//         code: "1193",
//         cancelled: false,
//         canBeRemoved: false,
//       },
//       {
//         vmBoxCode: null,
//         price: 95,
//         oppId: "00k77000003GewlAAC",
//         name: "Abby Setup",
//         liveReceptionistMinutesInPackage: null,
//         liveChatsInPackage: null,
//         id: "01ti0000006QbMVAA0",
//         discount: null,
//         code: "4013",
//         cancelled: false,
//         canBeRemoved: false,
//       },
//     ],
//     closeDate: "2022-10-20",
//   },
//   packages: [],
//   orderNumber: null,
//   nextDueDate: null,
//   name: null,
//   liveChatFreeTrialEndDate: "2022-10-18",
//   invoiceId: null,
//   futurePackages: null,
//   description: null,
//   closeDate: null,
//   chatTrial: {
//     totalRecurringPrice: 99,
//     packages: [
//       {
//         vmBoxCode: null,
//         price: 129,
//         oppId: "00k77000003GewrAAC",
//         name: "Abby LiveChat 10",
//         liveReceptionistMinutesInPackage: null,
//         liveChatsInPackage: 10,
//         id: "01t6e000009MnLgAAK",
//         discount: null,
//         code: "1500",
//         cancelled: false,
//         canBeRemoved: false,
//       },
//       {
//         vmBoxCode: null,
//         price: -30,
//         oppId: "00k77000003GewsAAC",
//         name: "Abby LiveChat 10 Bundle Discount",
//         liveReceptionistMinutesInPackage: null,
//         liveChatsInPackage: null,
//         id: "01t77000000GiGmAAK",
//         discount: null,
//         code: "9019",
//         cancelled: false,
//         canBeRemoved: false,
//       },
//     ],
//     closeDate: "2022-10-19",
//   },
//   billingStartDateLiveReceptionist: "2022-10-20",
//   billingStartDateLiveChat: "2022-10-20",
//   billingStartDate: null,
//   billingEndDateLiveReceptionist: "2022-11-17",
//   billingEndDateLiveChat: "2022-11-17",
//   amountDue: null,
//   amount: null,
//   abbyFreeTrialEndDate: "2022-10-19",
// };
