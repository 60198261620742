import { AxiosError } from "axios";
import { useQuery } from "react-query";
import type { QueryFunction } from "react-query";
import axios from "src/services/network/axios";
import { StringOrNull } from "src/types";
import { useToast } from "@chakra-ui/react";
import { useCompanySelection } from "src/store/companySelectionState";
import getCurrentCallStatusKeys from "src/hooks/queries/account/useCurrentCallStatusQuery/getCurrentCallStatusKeys";
import type { QueryKeysType } from "src/hooks/queries/account/useCurrentCallStatusQuery/getCurrentCallStatusKeys";

type YesOrNoType = "Yes" | "No" | "Emergency Calls Only" | null;

export type CurrentCallStatusType = {
  username: string;
  userId: string;
  unavailableTakeCallsExplain: StringOrNull;
  unavailabilityExplanationOther: StringOrNull;
  unavailabilityExplanation: StringOrNull;
  title: StringOrNull;
  thirdTransferTypeCustomName: StringOrNull;
  thirdTransferType: StringOrNull;
  thirdTransferPhone: StringOrNull;
  takeNewInquiryCalls: boolean;
  takeCustomerServiceCalls: boolean;
  takeCallsDefault: YesOrNoType;
  takeCalls: YesOrNoType;
  takeBillingCalls: boolean;
  statusExpirationDate: string;
  secondTransferTypeCustomName: StringOrNull;
  secondTransferType: StringOrNull;
  secondTransferPhone: StringOrNull;
  otherTypesOfCallsTaken: StringOrNull;
  okToGiveOutEmailAddress: YesOrNoType;
  emailNotificationsToPrimaryEmail: boolean;
  name: string;
  lastName: string;
  id: string;
  firstTransferTypeCustomName: StringOrNull;
  firstTransferType: StringOrNull;
  firstTransferPhone: StringOrNull;
  firstName: string;
  email: string;
  doNotConnect: string;
  currentUnavailabilityExplanationOther: string;
  currentUnavailabilityExplanation: string;
  currentStatusNote: string;
  connectOnly: string;
  callType: StringOrNull;
  availableDays: StringOrNull;
};

type ParamType = {
  onSuccess?: (arg: any) => void;
};

const useCurrentCallStatusQuery = (options?: ParamType) => {
  const companySelection = useCompanySelection();
  const companyId = companySelection!.activeCompany as string;
  const contactId = companySelection!.activeCompanyObject?.contactId as string;

  const toast = useToast();
  const queryKey = getCurrentCallStatusKeys({
    companyId,
    contactId,
  });

  const query = useQuery({
    queryKey,
    queryFn: fetchCurrentCallStatus,
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      options?.onSuccess && options.onSuccess(response);
    },
    onError: (err: AxiosError) => {
      const errMessage = err?.response?.data?.message;
      toast({
        title: "Error",
        description: errMessage || "Failed.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });
  return query;
};

export default useCurrentCallStatusQuery;

const fetchCurrentCallStatus: QueryFunction<
  CurrentCallStatusType,
  QueryKeysType
> = async ({ queryKey }) => {
  const options = queryKey[1];
  const companyId = options.companyId;
  const contactId = options.contactId;

  //  {{baseUrl}}/companies/0018N00000CXthyQAD/contacts/0038N00000Al70KQAR
  const request = await axios.get(
    `/companies/${companyId}/contacts/${contactId}`
  );
  return request?.data || [];
};

// SAMPLE RESPONSE**

// const foo = {
//   username: "UserScopic2",
//   userId: "a2U8N000000H8IhUAK",
//   unavailableTakeCallsExplain: null,
//   unavailabilityExplanationOther: null,
//   unavailabilityExplanation: "Unavailable",
//   title: null,
//   thirdTransferTypeCustomName: null,
//   thirdTransferType: null,
//   thirdTransferPhone: null,
//   takeNewInquiryCalls: false,
//   takeCustomerServiceCalls: false,
//   takeCalls: "No",
//   takeBillingCalls: false,
//   statusExpirationDate: null,
//   secondTransferTypeCustomName: null,
//   secondTransferType: null,
//   secondTransferPhone: null,
//   otherTypesOfCallsTaken: null,
//   okToGiveOutEmailAddress: "No",
//   name: "User Scopic",
//   lastName: "Scopic",
//   id: "0038N00000Al70KQAR",
//   firstTransferTypeCustomName: null,
//   firstTransferType: "Other",
//   firstTransferPhone: "123564899",
//   firstName: "User",
//   email: "user-scopic@mailinator.com",
//   doNotConnect: "+790111111111",
//   currentUnavailabilityExplanationOther: "My other explanation",
//   currentUnavailabilityExplanation: "Unavailable",
//   currentStatusNote: "And ofcourse status, how without it",
//   connectOnly: "+390111111111",
//   callType: null,
//   availableDays: "Send the caller to my cell phone voicemail",
// };
