import { useEffect } from "react";
import useCompanyInfo from "src/hooks/queries/dashboard/useCompanyInfoQuery/useCompanyInfoQuery";
import useGetUserQuery from "src/hooks/queries/settings/useGetUserQuery/useGetUserQuery";
import { useLoggedInAs } from "src/store/loggedInAs";
import useUserAccount from "src/store/userAccountState";

const Pendo = () => {
  const { data: companyInfoData } = useCompanyInfo();
  const { userAccount } = useUserAccount();
  const userId = userAccount?.id;
  const { data: userData } = useGetUserQuery(userId as string, !!userId);
  const { isAdminLoggedInAsUser } = useLoggedInAs();

  const loadPendoScript = (apiKey: string) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
    document.head.appendChild(script);
    return script;
  };

  const initializePendo = (
    userData: any,
    companyInfoData: any,
    isAdminLoggedInAsUser: boolean
  ) => {
    const visitorId =
      (isAdminLoggedInAsUser ? "int-" : "") + (userData?.guid || "guest");

    if (window.pendo.isReady()) {
      window.pendo.identify({
        visitor: {
          id: visitorId,
          full_name: userData?.name || "guest",
          email: userData?.email || "guest@guest",
        },
        account: {
          id: companyInfoData?.guid || "guest",
          name: companyInfoData?.name || "guest",
        },
      });
    } else {
      window.pendo.initialize({
        visitor: {
          id: visitorId,
          full_name: userData?.name || "guest",
          email: userData?.email || "guest@guest",
        },
        account: {
          id: companyInfoData?.guid || "guest",
          name: companyInfoData?.name || "guest",
        },
      });
    }
  };

  useEffect(() => {
    const pendoKey = process.env.REACT_APP_PENDO_API_KEY;

    if (pendoKey) {
      const script = loadPendoScript(pendoKey);
      if (window.pendo && userData && companyInfoData) {
        initializePendo(userData, companyInfoData, isAdminLoggedInAsUser);
      }

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [userData, companyInfoData]);

  return null;
};

export default Pendo;
