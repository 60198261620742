import React from "react";
import { FC, useState } from "react";
import createContextSet from "src/utils/createContextSet";
import chainGet from "src/services/network/chainGet";
import useGetSubscriptionsQuery, {
  SubscriptionsDataType,
} from "src/hooks/queries/subscription/useGetSubscriptionsQuery/useGetSubscriptionsQuery";
import useActiveSubscriptionQuery, {
  PackageType,
  ActiveSubscriptionType,
} from "src/hooks/queries/subscription/useActiveSubscriptionQuery/useActiveSubscription";
import useTimeZone from "src/hooks/useTimeZone";
import dayjs from "src/utils/dayjs";
import {
  formateDate,
  getTotalUsages,
  getTotalChatAndMinutesAllowance,
} from "src/utils/helper";
import { StringOrNull } from "src/types";
import type { InvoicePreviewPayloadType } from "src/hooks/queries/invoices/useInvoicePreview";
import removeNulls from "src/utils/removeNulls";
import { uniqBy } from "ramda";
import { add, intervalToDuration } from "date-fns";
import useIsMainAccountType from "src/hooks/useIsMainAccountType";
import useCompanyInfo from "src/hooks/queries/dashboard/useCompanyInfoQuery/useCompanyInfoQuery";

export const checkIsLiveChatPak = (subscriptionPackage: PackageType) =>
  typeof subscriptionPackage.liveChatsInPackage === "number" &&
  subscriptionPackage.liveChatsInPackage > 0;

export const checkIsLiveRecepPak = (subscriptionPackage: PackageType) =>
  typeof subscriptionPackage.liveReceptionistMinutesInPackage === "number" &&
  subscriptionPackage.liveReceptionistMinutesInPackage > 0;

export const checkIsAiRecepPak = (subscriptionPackage: PackageType) =>
  typeof subscriptionPackage.aiReceptionistMinutesInPackage === "number" &&
  subscriptionPackage.aiReceptionistMinutesInPackage > 0;

const uniqByVMBox = uniqBy(
  (invoice: InvoicePreviewPayloadType) => invoice.vmBoxCode
);

const uniqByOppId = uniqBy(
  (invoice: InvoicePreviewPayloadType) => invoice.oppId
);
const uniqByPackageName = uniqBy((pak: PackageType) => pak?.name);

type SubscriptionReturnType = {
  setSubscriptionPackages: React.Dispatch<
    React.SetStateAction<InvoicePreviewPayloadType[]>
  >;
  subscriptionPackages: InvoicePreviewPayloadType[];
  packages: PackageType[];
  invoiceId: string;
  totalChatAndCellUsed: {
    minutesRemaining: number;
    minutesUsedSoFar: number;
    aiMinutesRemaining: number;
    aiMinutesUsedSoFar: number;
    chatsRemaining: number;
    chatsUsedSoFar: number;
  };
  totalRecurringPrice: number;
  totalPrice: number;
  billingStartDate: StringOrNull;
  billingEndDate: StringOrNull;
  getSubscriptionPackage: (code: number) => PackageType;
  currentSubscriptionIds: string[];
  addOnList: PackageType[];
  nextPayment: string;
  totalChatAndMinutesAllowance: {
    totalChatAllowance: number | null;
    totalCallAllowance: number | null;
    totalAiCallAllowance: number | null;
  };
  aiPackages: PackageType[];
  setAiRecepPackageX: React.Dispatch<
    React.SetStateAction<PackageType | null | undefined>
  >;
  aiRecepPackageX: PackageType | null | undefined;
  clearAllState: () => void;
  highPricedAddOn: PackageType;
  lowerPriceAddOn: PackageType;
  activeSubscription: ActiveSubscriptionType | undefined;
  liveChatTrialPackages: PackageType[];
  allChatTrialPackages: PackageType[];
  aiReceptionistTrialPackages: PackageType[];
  allAiRecepTrialPackages: PackageType[];
  liveReceptionistTrialPackages: PackageType[];
  allRecepTrialPackages: PackageType[];
  totalTrialChatAllowance: number;
  totalTrialMinutesAllowance: number;
  subsData: SubscriptionsDataType;
  //
  setMiscPackages: React.Dispatch<
    React.SetStateAction<InvoicePreviewPayloadType[]>
  >;
  miscPackages: InvoicePreviewPayloadType[];
  //
  setAddOnPackages: React.Dispatch<
    React.SetStateAction<InvoicePreviewPayloadType[]>
  >;
  addOnPackages: InvoicePreviewPayloadType[];
  handleAddAddons: (
    value: Required<
      Omit<InvoicePreviewPayloadType, "oppId" | "isAIReceptionist">
    >
  ) => void;
  handleRemoveAddOn: (vmBoxCode: string) => void;
  // --
  setLiveChatPackageX: React.Dispatch<
    React.SetStateAction<PackageType | null | undefined>
  >;
  liveChatPackageX: PackageType | null | undefined;
  setLiveRecepPackageX: React.Dispatch<
    React.SetStateAction<PackageType | null | undefined>
  >;
  liveRecepPackageX: PackageType | null | undefined;
  setAllPackagesfromChatTrial: (chatPackage: PackageType) => void;
  setAllPackagesfromRecepTrial: (recepPackage: PackageType) => void;
  setAllPackagesfromAiRecepTrial: (recepPackage: PackageType) => void;
  liveRecepDateRangeLabel: StringOrNull;
  liveChatDateRangeLabel: StringOrNull;
  getCountdownDays: (
    subType: "LIVE_CHAT" | "LIVE_RECEPTIONIST" | "AI_RECEPTIONIST",
    isActive: boolean,
    isTrial: boolean
  ) => number;
  refetchActiveSub: () => void;
  findAutoVoicemailPackage: () => PackageType | undefined;
  hasAutoVoicemailPackage: boolean;
  isLoading: boolean;
  aiRecepPackageSelection: PackageType[];
  liveRecepPackageSelection: PackageType[];
  liveChatPackageSelection: PackageType[];

  billingStartDateAIReceptionist: string | null;
  billingEndDateAIReceptionist: string | null;
  aiCallAllowance: number | null;
  aiMinutesUsedSoFar: number | null;
};

const [useSubscriptionCtx, SubscriptionCtxProvider] =
  createContextSet<SubscriptionReturnType>();

const getDateRangeLabel = (startDateString: string, endDateString: string) => {
  if (!startDateString && !endDateString) return null;

  const startStrArr = startDateString.split("-");
  const procStartDate = `${startStrArr[1]}/${startStrArr[2]}/${startStrArr[0]}`;

  const endStrArr = endDateString.split("-");
  const procEndDate = `${endStrArr[1]}/${endStrArr[2]}/${endStrArr[0]}`;

  return `${procStartDate} - ${procEndDate}`;
};

const SubscriptionContextProvider: FC = ({ children }) => {
  const isMainAccountType = useIsMainAccountType();
  const { data: companyInfo, isLoading: isCompanyInfoLoading } =
    useCompanyInfo();
  const subscriptionsQuery = useGetSubscriptionsQuery();
  const { data: subscriptionQueryData, isLoading: isSubLoading } =
    subscriptionsQuery;

  const {
    data: activeSubscription,
    refetch,
    isLoading: isActiveSubLoading,
  } = useActiveSubscriptionQuery();

  const isLoading = isSubLoading || isActiveSubLoading || isCompanyInfoLoading;

  const getTimezoneDate = useTimeZone();

  const nextDueDate = chainGet(activeSubscription?.nextDueDate, null);
  const billingEndDate = nextDueDate
    ? getTimezoneDate(nextDueDate, "MM/DD/YYYY")
    : "";
  const billingStartDate = nextDueDate
    ? getTimezoneDate(
        dayjs(nextDueDate).subtract(1, "month").toISOString(),
        "MM/DD/YYYY"
      )
    : "";

  const liveRecepDateRangeLabel = getDateRangeLabel(
    chainGet(activeSubscription?.billingStartDateLiveReceptionist, ""),
    chainGet(activeSubscription?.billingEndDateLiveReceptionist, "")
  );

  const liveChatDateRangeLabel = getDateRangeLabel(
    chainGet(activeSubscription?.billingStartDateLiveChat, ""),
    chainGet(activeSubscription?.billingEndDateLiveChat, "")
  );

  const subsData = chainGet(subscriptionQueryData?.data, []);
  const addOnList = subsData.filter((sub) =>
    [2497, 2499].includes(parseInt(sub.code))
  );

  const priceSortedAddOnList = addOnList.sort(
    (item1: PackageType, item2: PackageType) => {
      const price1 = item1.price;
      const price2 = item2.price;
      return price2 - price1;
    }
  );
  const highPricedAddOn = priceSortedAddOnList[0];
  const lowerPriceAddOn = priceSortedAddOnList[1];

  // for codes*  src/constants/subscriptionPackages.ts
  const getSubscriptionPackage = React.useCallback(
    (code: number) => {
      return subsData.filter((item) => {
        return parseInt(item.code) === code;
      })[0];
    },
    [subsData]
  );
  const [miscPackages, setMiscPackages] = useState<InvoicePreviewPayloadType[]>(
    []
  );
  const [addOnPackages, setAddOnPackages] = useState<
    InvoicePreviewPayloadType[]
  >([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    Array<InvoicePreviewPayloadType>
  >([]);

  const [liveChatPackageX, setLiveChatPackageX] = useState<
    PackageType | null | undefined
  >();
  const [liveRecepPackageX, setLiveRecepPackageX] = useState<
    PackageType | null | undefined
  >();

  const [aiRecepPackageX, setAiRecepPackageX] = useState<
    PackageType | null | undefined
  >(null);

  const nextPayment = activeSubscription?.nextDueDate
    ? formateDate(activeSubscription?.nextDueDate)
    : "";

  const allChatTrialPackages = chainGet(
    activeSubscription?.chatTrial?.packages,
    []
  );

  // Packages from Trial Services
  const liveChatTrialPackages = allChatTrialPackages.filter((item) => {
    const isLiveChatPackage = checkIsLiveChatPak(item);

    return isLiveChatPackage;
  });

  const allRecepTrialPackages = chainGet(
    activeSubscription?.receptionistTrial?.packages,
    []
  );
  const liveReceptionistTrialPackages = allRecepTrialPackages.filter((item) => {
    const isLiveRecepPackage = checkIsLiveRecepPak(item);

    return isLiveRecepPackage;
  });

  const allAiRecepTrialPackages = chainGet(
    activeSubscription?.aiReceptionistTrial?.packages,
    []
  );
  const aiReceptionistTrialPackages = allAiRecepTrialPackages.filter((item) => {
    const isAiRecepPackage = checkIsAiRecepPak(item);

    return isAiRecepPackage;
  });

  const totalTrialChatAllowance = 50;
  const totalTrialMinutesAllowance = 500;

  // Packages from paid services
  const packages = chainGet(activeSubscription?.packages, []);
  const currentSubscriptionIds = packages.map((p) => p.id);
  const totalPrice = chainGet(activeSubscription?.totalPrice, 0);
  const totalRecurringPrice = chainGet(
    activeSubscription?.totalRecurringPrice,
    0
  ) as number;
  const invoiceId = chainGet(activeSubscription?.invoiceId, "");
  const companyInfoObj = chainGet(companyInfo, {});
  const totalChatAndCellUsed = getTotalUsages(
    companyInfoObj,
    isMainAccountType
  );
  const totalChatAndMinutesAllowance =
    getTotalChatAndMinutesAllowance(companyInfoObj);

  // At least 1VM should be assigned to the highest priced invoice
  const handleAddAddons = (
    value: Required<
      Omit<InvoicePreviewPayloadType, "oppId" | "isAIReceptionist">
    >
  ) => setAddOnPackages((prev) => [...prev, value]);

  const handleRemoveAddOn = (vmBoxCode: string) => {
    const newAddOns = addOnPackages.filter(
      (item) => vmBoxCode !== item?.vmBoxCode
    );
    setAddOnPackages(newAddOns);
  };

  const getCountdownDays = (
    subType: "LIVE_CHAT" | "LIVE_RECEPTIONIST" | "AI_RECEPTIONIST",
    isExpired: boolean,
    isTrial: boolean
  ) => {
    const liveChatFreeTrialEndDate = chainGet(
      activeSubscription?.liveChatFreeTrialEndDate,
      null
    );

    const billingEndDateLiveChat = chainGet(
      activeSubscription?.billingEndDateLiveChat,
      null
    );

    const abbyFreeTrialEndDate = chainGet(
      activeSubscription?.abbyFreeTrialEndDate,
      null
    );

    const billingEndDateLiveReceptionist = chainGet(
      activeSubscription?.billingEndDateLiveReceptionist,
      null
    );

    // FOR "Active" Trial Subscriptions use billingEndDateLiveChat
    const aiReceptionistFreeTrialEnd = chainGet(
      companyInfo?.aiReceptionistFreeTrialEnd,
      null
    );
    // For "Expired" Trial Subscription use liveChatFreeTrialEndDate
    const billingEndDateAIReceptionist = chainGet(
      activeSubscription?.billingEndDateAIReceptionist,
      null
    );

    const getEndDateBasis = () => {
      if (subType === "LIVE_CHAT" && isExpired) return liveChatFreeTrialEndDate;

      if (subType === "LIVE_CHAT" && !isExpired) return billingEndDateLiveChat;

      if (subType === "LIVE_RECEPTIONIST" && isExpired)
        return abbyFreeTrialEndDate;

      if (subType === "LIVE_RECEPTIONIST" && !isExpired)
        return billingEndDateLiveReceptionist;

      //~ AI Receptionist goes here ~//
      if (subType === "AI_RECEPTIONIST" && (isExpired || isTrial))
        return aiReceptionistFreeTrialEnd;

      if (subType === "AI_RECEPTIONIST" && !isExpired)
        return billingEndDateAIReceptionist;

      return null;
    };

    const endDateBasis = getEndDateBasis();

    if (!endDateBasis) return 0;

    const procEndDate = add(new Date(endDateBasis), {
      days: 1,
    });

    const duration = intervalToDuration({
      start: new Date(),
      end: endDateBasis ? procEndDate : new Date(),
    });

    const epochStartDate = new Date().getTime();
    const epochEndDate = procEndDate.getTime();
    const isPastEndDate = epochStartDate > epochEndDate;
    const countdownDays = isPastEndDate ? 0 : (duration.days as number);
    return countdownDays;
  };

  React.useEffect(() => {
    const collatedPackages = [
      {
        id: liveRecepPackageX?.id,
        oppId: liveRecepPackageX?.oppId,
        isLiveReceptionist: true,
      },
      {
        id: liveChatPackageX?.id,
        oppId: liveChatPackageX?.oppId,
        isLiveReceptionist: false,
      },
      {
        id: aiRecepPackageX?.id,
        oppId: aiRecepPackageX?.oppId,
        isAIReceptionist: true,
      },
      ...addOnPackages,
      ...miscPackages.map((item) => ({
        id: item.id,
        oppId: item?.oppId,
        isLiveReceptionist: item.isLiveReceptionist,
      })),
    ] as InvoicePreviewPayloadType[];

    const selectedPackages = collatedPackages.reduce(
      (acc: InvoicePreviewPayloadType[], curr) => {
        if (!curr?.id) {
          return acc;
        }
        if (curr.isAIReceptionist !== undefined) {
          const vmBoxCode = curr?.vmBoxCode;
          const oppId = curr?.oppId;
          const isLiveReceptionist = curr?.isLiveReceptionist;
          const isAIReceptionist = curr?.isAIReceptionist;

          const newInvoicePreview = removeNulls({
            id: curr.id,
            vmBoxCode,
            oppId,
            isLiveReceptionist,
            isAIReceptionist,
          });

          acc = [...acc, newInvoicePreview];
          return acc;
        }
        const vmBoxCode = curr?.vmBoxCode;
        const oppId = curr?.oppId;
        const isLiveReceptionist = curr?.isLiveReceptionist;

        const newInvoicePreview = removeNulls({
          id: curr.id,
          vmBoxCode,
          oppId,
          isLiveReceptionist,
        });

        acc = [...acc, newInvoicePreview];
        return acc;
      },
      []
    );
    setSubscriptionPackages([...selectedPackages]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liveChatPackageX?.id,
    liveRecepPackageX?.id,
    aiRecepPackageX?.id,
    miscPackages,
    addOnPackages,
  ]);

  const setAllPackagesfromChatTrial = (chatPackage: PackageType) => {
    if (chatPackage) {
      setLiveChatPackageX(chatPackage);

      const liveChatAddOnPackages = allChatTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) {
            const vmBoxCode: string = curr.vmBoxCode;

            return [
              ...acc,
              {
                vmBoxCode,
                id: curr.id,
                oppId: curr?.oppId,
                isLiveReceptionist: false,
              },
            ];
          }
          return acc;
        },
        []
      );

      const liveChatTrialPackageInvoices = allChatTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) return acc;
          if (checkIsLiveChatPak(curr)) return acc;
          if (curr.id === chatPackage.id) return acc;
          // ==
          if (curr?.vmBoxCode) return acc;

          return [
            ...acc,
            {
              id: curr.id,
              oppId: curr?.oppId,
              isLiveReceptionist: false,
            },
          ];
        },
        []
      );

      setAddOnPackages((prev) =>
        uniqByVMBox([...prev, ...liveChatAddOnPackages])
      );
      setMiscPackages((prev) =>
        // uniqById([...prev, ...liveChatTrialPackageInvoices])
        uniqByOppId([...prev, ...liveChatTrialPackageInvoices])
      );
      return;
    }
  };

  const setAllPackagesfromRecepTrial = (recepPackage: PackageType) => {
    if (recepPackage) {
      setLiveRecepPackageX(recepPackage);

      const liveRecepAddOnPackages = allRecepTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) {
            const vmBoxCode = curr.vmBoxCode;
            const newRecepAddOn = removeNulls({
              vmBoxCode,
              id: curr.id,
              oppId: curr?.oppId,
              isLiveReceptionist: true,
            });
            return [...acc, newRecepAddOn];
          }
          return acc;
        },
        []
      );

      const liveRecepTrialPackageInvoices = allRecepTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (checkIsLiveRecepPak(curr)) return acc;
          if (curr.id === recepPackage.id) return acc;
          // ===
          if (curr?.vmBoxCode) return acc;
          return [
            ...acc,
            {
              id: curr.id,
              oppId: curr?.oppId,
              isLiveReceptionist: true,
            },
          ];
        },
        []
      );

      setAddOnPackages((prev) =>
        uniqByVMBox([...prev, ...liveRecepAddOnPackages])
      );

      setMiscPackages((prev) =>
        // uniqById([...prev, ...liveRecepTrialPackageInvoices])
        uniqByOppId([...prev, ...liveRecepTrialPackageInvoices])
      );
      return;
    }
  };

  const setAllPackagesfromAiRecepTrial = (recepPackage: PackageType) => {
    if (recepPackage) {
      setAiRecepPackageX(recepPackage);

      const aiRecepAddOnPackages = allAiRecepTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) {
            const vmBoxCode = curr.vmBoxCode;
            const newRecepAddOn = removeNulls({
              vmBoxCode,
              id: curr.id,
              oppId: curr?.oppId,
              isAiReceptionist: true,
            });
            return [...acc, newRecepAddOn];
          }
          return acc;
        },
        []
      );

      const aiRecepTrialPackageInvoices = allAiRecepTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (checkIsAiRecepPak(curr)) return acc;
          if (curr.id === recepPackage.id) return acc;
          // ===
          if (curr?.vmBoxCode) return acc;
          return [
            ...acc,
            {
              id: curr.id,
              oppId: curr?.oppId,
              isAiReceptionist: true,
            },
          ];
        },
        []
      );

      setAddOnPackages((prev) =>
        uniqByVMBox([...prev, ...aiRecepAddOnPackages])
      );

      setMiscPackages((prev) =>
        // uniqById([...prev, ...aiRecepTrialPackageInvoices])
        uniqByOppId([...prev, ...aiRecepTrialPackageInvoices])
      );
      return;
    }
  };

  const findAutoVoicemailPackage = () => {
    return (
      chainGet(activeSubscription?.futurePackages, []).find((item) => {
        return item.name === "Auto Voicemail Transcription";
      }) ||
      packages.find((item) => item.name === "Auto Voicemail Transcription")
    );
  };

  const hasAutoVoicemailPackage = !!findAutoVoicemailPackage();

  const clearAllState = () => {
    setAddOnPackages(() => []);
    setLiveChatPackageX(null);
    setLiveRecepPackageX(null);
    setAiRecepPackageX(null);
    setMiscPackages(() => []);
    setSubscriptionPackages(() => []);
  };

  const airCustomPackages = chainGet(
    activeSubscription?.airCustomPackages,
    []
  )!;
  const lrCustomPackages = chainGet(activeSubscription?.lrCustomPackages, [])!;
  const lcCustomPackages = chainGet(activeSubscription?.lcCustomPackages, [])!;

  const getLiveRecepPagkageSelectionList = () => {
    const allRecepPackages = [
      getSubscriptionPackage(1193),
      getSubscriptionPackage(1191),
      getSubscriptionPackage(1190),
      getSubscriptionPackage(1189),
      ...lrCustomPackages,
    ].sort((item1, item2) => {
      const mins1 = item1.liveReceptionistMinutesInPackage!;
      const mins2 = item2.liveReceptionistMinutesInPackage!;
      return mins1 - mins2;
    });
    return allRecepPackages;
  };

  const liveRecepPackageSelection = uniqByPackageName(
    getLiveRecepPagkageSelectionList()
  );

  const getAiRecepPagkageSelectionList = () => {
    const allRecepPackages = [
      getSubscriptionPackage(2005),
      getSubscriptionPackage(2004),
      getSubscriptionPackage(2003),
      getSubscriptionPackage(2002),
      getSubscriptionPackage(2001),
      getSubscriptionPackage(2000),
      ...airCustomPackages,
    ].sort((item1, item2) => {
      const mins1 = item1.aiReceptionistMinutesInPackage!;
      const mins2 = item2.aiReceptionistMinutesInPackage!;
      return mins1 - mins2;
    });
    return allRecepPackages;
  };

  const aiRecepPackageSelection = uniqByPackageName(
    getAiRecepPagkageSelectionList()
  );

  const getLiveChatPagkageSelectionList = () => {
    const allChatPackages = [
      getSubscriptionPackage(1500),
      getSubscriptionPackage(1501),
      getSubscriptionPackage(1502),
      ...lcCustomPackages,
    ].sort((item1, item2) => {
      const chatCount1 = item1.liveChatsInPackage!;
      const chatCount2 = item2.liveChatsInPackage!;
      return chatCount1 - chatCount2;
    });
    return allChatPackages;
  };

  const liveChatPackageSelection = uniqByPackageName(
    getLiveChatPagkageSelectionList()
  );

  const getAIPagkageSelectionList = () => {
    //~ Use dynamic filtering instead of hardcoding package codes ~//
    const aiSubscriptionPackages = subsData.filter((sub) => {
      const aiMinutes = sub?.aiReceptionistMinutesInPackage ?? 0;
      return aiMinutes > 0;
    });

    //~ Sort by the number of AI Receptionist Minutes in the package ~//
    return aiSubscriptionPackages.sort((item1, item2) => {
      const mins1 = item1.aiReceptionistMinutesInPackage!;
      const mins2 = item2.aiReceptionistMinutesInPackage!;
      return mins1 - mins2;
    });
  };
  const aiPackages = uniqByPackageName(getAIPagkageSelectionList());

  const aiCallAllowance = packages.reduce((acc, pkg) => {
    return acc + (pkg.aiReceptionistMinutesInPackage ?? 0);
  }, 0);

  const aiMinutesUsedSoFar = 0;

  const billingStartDateAIReceptionist = chainGet(
    activeSubscription?.billingStartDateAIReceptionist,
    null
  );

  const billingEndDateAIReceptionist = chainGet(
    activeSubscription?.billingEndDateAIReceptionist,
    null
  );

  const contextValue = {
    aiRecepPackageSelection,
    liveRecepPackageSelection,
    liveChatPackageSelection,

    handleAddAddons,
    handleRemoveAddOn,
    setAddOnPackages,
    addOnPackages,
    setMiscPackages,
    miscPackages,
    aiPackages,
    aiRecepPackageX,
    setAiRecepPackageX,
    clearAllState,
    subscriptionPackages,
    setSubscriptionPackages,
    packages,
    invoiceId,
    totalChatAndCellUsed,
    totalPrice,
    totalRecurringPrice,
    billingStartDate,
    billingEndDate,
    billingStartDateAIReceptionist,
    billingEndDateAIReceptionist,
    aiCallAllowance,
    aiMinutesUsedSoFar,
    getSubscriptionPackage,
    currentSubscriptionIds,
    addOnList,
    nextPayment,
    totalChatAndMinutesAllowance,
    highPricedAddOn,
    lowerPriceAddOn,
    activeSubscription,
    aiReceptionistTrialPackages,
    allAiRecepTrialPackages,
    liveReceptionistTrialPackages,
    allRecepTrialPackages,
    liveChatTrialPackages,
    allChatTrialPackages,
    totalTrialChatAllowance,
    totalTrialMinutesAllowance,
    subsData,
    // --
    setLiveChatPackageX,
    liveChatPackageX,
    liveRecepPackageX,
    setLiveRecepPackageX,
    setAllPackagesfromChatTrial,
    setAllPackagesfromRecepTrial,
    setAllPackagesfromAiRecepTrial,
    liveRecepDateRangeLabel,
    liveChatDateRangeLabel,
    getCountdownDays,
    refetchActiveSub: refetch,
    findAutoVoicemailPackage,
    hasAutoVoicemailPackage,
    isLoading,
  };

  return (
    <SubscriptionCtxProvider value={contextValue}>
      {children}
    </SubscriptionCtxProvider>
  );
};

export default SubscriptionContextProvider;
export const useSubscriptionContext = () => useSubscriptionCtx();
