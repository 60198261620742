import { useRef } from "react";
import { Box, Button, Flex, Input } from "@chakra-ui/react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";
import { MdAccessTime } from "react-icons/md";

export type CustomDatePickerPropType = {
  id: string;
  className: string;
  ariaLabel: string;
  value: string | undefined;
  onChange: (...event: any[]) => void;
  setValue: (value: string | undefined) => void;
};

const ContactStatusTimePicker = ({
  id,
  className,
  ariaLabel,
  value,
  onChange,
  setValue,
}: CustomDatePickerPropType) => {
  const {
    formatDateObjInMyTimeZoneForInput,
    moveDateToMyTimeZone,
    getMyTimeZoneAbbrString,
  } = useFormatDateInMyTimeZone();
  const inputRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<DatePicker>(null);
  const dateStringFullVal = value
    ? formatDateObjInMyTimeZoneForInput(dayjs(value))
    : null;

  const dateStringVal = value
    ? formatDateObjInMyTimeZoneForInput(dayjs(value), "h:mm a")
    : null;

  return (
    <DatePicker
      id={id}
      ref={dateRef}
      enableTabLoop={false}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat={"h:mm a"}
      selected={dateStringFullVal ? new Date(dateStringFullVal) : null}
      onChange={(newDate: any) => {
        const dateObj = dateStringFullVal
          ? dayjs.utc(dateStringFullVal)
          : dayjs.utc();
        const newDateObj = dayjs.utc(
          dayjs(newDate).format("YYYY-MM-DD h:mm A"),
          "YYYY-MM-DD h:mm A"
        );
        const mergedDate = dayjs.utc(
          `${dateObj.format("YYYY-MM-DD")} ${newDateObj.format("h:mm A")}`,
          "YYYY-MM-DD h:mm A"
        );
        const dateVal = moveDateToMyTimeZone(
          mergedDate.format("YYYY-MM-DD HH:mm")
        );
        onChange(dateVal);
        setValue(dateVal?.toString() || undefined);
        if (inputRef.current) {
          inputRef.current.value = dayjs(newDate).format("h:mm a") || "";
        }
      }}
      onChangeRaw={(e) => {
        let typedDate = e.target.value;
        typedDate = typedDate.replace(/[^0-9:\sAPMapm]/g, "");
        if (typedDate.length > 8) {
          typedDate = typedDate.slice(0, 8);
        }
        e.target.value = typedDate;
        const isValid = dayjs(
          typedDate,
          ["h:mm a", "hh:mm a", "h:mm A", "hh:mm A", "HH:mm"],
          true
        ).isValid();
        if (!isValid) {
          e.preventDefault();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && inputRef.current) {
          setTimeout(() => inputRef.current?.blur(), 0);
        }
        if (e.key === "Tab" && inputRef.current) {
          setTimeout(() => {
            inputRef.current?.blur();
            dateRef.current?.setOpen(false);
          }, 0);
        }
      }}
      ariaLabelledBy={ariaLabel}
      customInput={
        <Flex
          direction={"row"}
          alignItems={"center"}
          gap={1}
          border={"1px solid #e2e8f0"}
          borderRadius={"xl"}
          backgroundColor={"#e4ecfc"}
          py={1}
          pl={3}
          pr={0}
          fontSize={"sm"}
          h={10}
        >
          <Input
            ref={inputRef}
            fontSize={"sm"}
            variant={"unstyled"}
            defaultValue={dateStringVal || ""}
            textTransform={"uppercase"}
            onBlur={(e) => {
              let typedDate = e.target.value;
              const validTime = dayjs(
                typedDate,
                ["h:mm A", "hh:mm A", "h:mm a", "hh:mm a", "HH:mm"],
                true
              );
              if (!validTime.isValid()) {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
                return;
              }
              if (dayjs(typedDate, ["HH:mm"], true).isValid()) {
                typedDate = validTime.format("h:mm A");
              }
              if (inputRef.current) {
                inputRef.current.value = typedDate;
              }
            }}
          />
          <Box
            key={value}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            minW={8}
          >
            {value ? getMyTimeZoneAbbrString(dayjs(value)) : ""}
          </Box>
          <Button variant={"ghost"} className={"custom-datepicker-icon"} px={0}>
            <MdAccessTime />
          </Button>
        </Flex>
      }
      className={className}
    />
  );
};

export default ContactStatusTimePicker;
