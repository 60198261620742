import { useRef } from "react";
import { Button, Flex, Input } from "@chakra-ui/react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { BiCalendar } from "react-icons/bi";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";

export type CustomDatePickerPropType = {
  id: string;
  className: string;
  ariaLabel: string;
  value: string | undefined;
  onChange: (...event: any[]) => void;
  setValue: (value: string | undefined) => void;
};

const ContactStatusDatePicker = ({
  id,
  className,
  ariaLabel,
  value,
  onChange,
  setValue,
}: CustomDatePickerPropType) => {
  const { formatDateObjInMyTimeZoneForInput, moveDateToMyTimeZone } =
    useFormatDateInMyTimeZone();
  const inputRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<DatePicker>(null);
  const dateStringFullVal = value
    ? formatDateObjInMyTimeZoneForInput(dayjs(value), "MM/DD/YYYY HH:mm") ||
      undefined
    : undefined;
  const dateStringVal = value
    ? formatDateObjInMyTimeZoneForInput(dayjs(value), "MM/DD/YYYY") || undefined
    : undefined;

  return (
    <DatePicker
      id={id}
      ref={dateRef}
      enableTabLoop={false}
      selected={dateStringVal ? new Date(dateStringVal) : null}
      onChange={(newDate: Date) => {
        const newDateObj = dayjs(newDate);
        const startTimeObj = dateStringFullVal
          ? dayjs(dateStringFullVal)
          : dayjs();
        const mergedDate = newDateObj
          .hour(startTimeObj.hour())
          .minute(startTimeObj.minute())
          .second(startTimeObj.second())
          .millisecond(startTimeObj.millisecond());
        const dateVal = moveDateToMyTimeZone(
          mergedDate.format("YYYY-MM-DD HH:mm")
        );
        onChange(dateVal?.toDate() || null);
        setValue(dateVal?.toString() || undefined);
        if (inputRef.current) {
          inputRef.current.value = mergedDate.format("MM/DD/YYYY") || "";
        }
      }}
      onChangeRaw={(e) => {
        const typedDate = e.target.value;
        if (!dayjs(typedDate, ["M/D/YYYY", "MM/DD/YYYY"], true).isValid()) {
          e.preventDefault();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && inputRef.current) {
          setTimeout(() => inputRef.current?.blur(), 0);
        }
        if (e.key === "Tab" && inputRef.current) {
          setTimeout(() => {
            inputRef.current?.blur();
            dateRef.current?.setOpen(false);
          }, 0);
        }
      }}
      ariaLabelledBy={ariaLabel}
      customInput={
        <Flex
          direction={"row"}
          alignItems={"center"}
          gap={0}
          border={"1px solid #e2e8f0"}
          borderRadius={"xl"}
          backgroundColor={"#e4ecfc"}
          py={1}
          pl={3}
          pr={0}
          fontSize={"sm"}
          h={10}
        >
          <Input
            ref={inputRef}
            fontSize={"sm"}
            variant={"unstyled"}
            defaultValue={dateStringVal || ""}
            onBlur={(e) => {
              const parsedDate = dayjs(
                e.target.value,
                ["M/D/YYYY", "MM/DD/YYYY"],
                true
              );
              if (!parsedDate.isValid()) {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }
            }}
          />
          <Button
            variant={"ghost"}
            className={"custom-datepicker-icon"}
            px={0}
            ml={"auto"}
          >
            <BiCalendar />
          </Button>
        </Flex>
      }
      className={className}
    />
  );
};

export default ContactStatusDatePicker;
